.paperFrame {
  display: grid;
  width: 480px;
  max-width: 100vw;
  border: 0.5px dotted #333;

  grid-template-columns: 100%;
  grid-template-rows: 360px 360px;

  position: relative;

  .controlGrid {
    grid-row: 1 / 2;
    grid-column: 1;
  }

  .resultGrid {
    grid-row: 2 / 3;
    grid-column: 1;
  }

  .allGrids {
    grid-row: 1 / 3;
    grid-column: 1;
  }

  @media print {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    grid-template-columns: 100vw;
    grid-template-rows: 45vh 45vh;
  }
}
